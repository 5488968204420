'use client';

import { useState } from 'react';
import Header from '@/components/Header';
import Link from 'next/link';

interface FaqItem {
    question: string;
    answer: string;
    category: string;
}

const faqs: FaqItem[] = [
    // Sourcing FAQs
    {
        question: "How do you verify suppliers in China?",
        answer: "We conduct thorough supplier verification through multiple steps: business license verification, factory audits, sample evaluations, and background checks. We also verify their export experience and certification compliance.",
        category: "Sourcing"
    },
    {
        question: "What is your minimum order quantity (MOQ) requirement?",
        answer: "MOQ varies by product and supplier. We work to negotiate the best possible MOQ for your needs, often combining orders or finding suppliers who accept lower MOQs for initial orders.",
        category: "Sourcing"
    },
    
    // Quality Control FAQs
    {
        question: "How do you ensure product quality?",
        answer: "We implement a comprehensive quality control system including pre-production inspection, during production checks, and final inspection before shipment. We also provide detailed quality specifications and work closely with manufacturers to meet standards.",
        category: "Quality Control"
    },
    {
        question: "What happens if quality issues are found?",
        answer: "If quality issues are detected, we immediately halt the production/shipping process and work with the supplier to resolve the issues. We ensure all problems are fixed before proceeding and can arrange for product rework if necessary.",
        category: "Quality Control"
    },

    // Shipping FAQs
    {
        question: "What shipping methods do you offer?",
        answer: "We offer various shipping options including sea freight (FCL/LCL), air freight, and express delivery. We recommend the most cost-effective option based on your timeline, volume, and budget.",
        category: "Shipping"
    },
    {
        question: "How long does shipping typically take?",
        answer: "Shipping times vary by method: Sea freight typically takes 25-35 days, air freight 5-7 days, and express delivery 3-5 days. We provide detailed tracking throughout the shipping process.",
        category: "Shipping"
    },

    // Payment FAQs
    {
        question: "What payment terms do you accept?",
        answer: "We typically work with T/T (30% deposit, 70% before shipment), L/C for large orders, and can discuss other payment terms based on the order value and relationship.",
        category: "Payment"
    },
    {
        question: "Are there any hidden costs?",
        answer: "We maintain full transparency in our pricing. All costs including sourcing, quality control, shipping, customs, and our service fees are clearly outlined in our quotations.",
        category: "Payment"
    }
];

const categories = ["All", "Sourcing", "Quality Control", "Shipping", "Payment"];

export default function FAQ() {
    const [activeCategory, setActiveCategory] = useState("All");
    const [openItem, setOpenItem] = useState<number | null>(null);

    const filteredFaqs = activeCategory === "All" 
        ? faqs 
        : faqs.filter(faq => faq.category === activeCategory);

    return (
        <>
            <Header />
            <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-16">
                {/* Hero Section */}
                <div className="container mx-auto px-4 py-16">
                    <h1 className="text-4xl font-bold text-gray-900 dark:text-white text-center mb-4">
                        Frequently Asked Questions
                    </h1>
                    <p className="text-gray-600 dark:text-gray-300 text-center max-w-2xl mx-auto">
                        Find answers to common questions about our sourcing, quality control, 
                        and shipping services. Can't find what you're looking for? 
                        <a href="/contact" className="text-primary dark:text-blue-400 hover:text-primary/80 dark:hover:text-blue-300 ml-1 font-medium">
                            Contact us
                        </a>.
                    </p>
                </div>

                <div className="container mx-auto px-4 py-12">
                    {/* Category Tabs */}
                    <div className="flex flex-wrap justify-center gap-2 mb-12">
                        {categories.map((category) => (
                            <button
                                key={category}
                                onClick={() => setActiveCategory(category)}
                                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors
                                    ${activeCategory === category
                                        ? 'bg-primary text-white'
                                        : 'bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                                    }`}
                            >
                                {category}
                            </button>
                        ))}
                    </div>

                    {/* FAQ Items */}
                    <div className="max-w-3xl mx-auto space-y-4">
                        {filteredFaqs.map((faq, index) => (
                            <div
                                key={index}
                                className="bg-white dark:bg-gray-800 rounded-lg shadow-[rgba(17,17,26,0.05)_0px_1px_0px,rgba(17,17,26,0.1)_0px_0px_8px] dark:shadow-[rgba(0,0,0,0.1)_0px_0px_8px]"
                            >
                                <button
                                    className="w-full px-6 py-4 text-left flex justify-between items-center"
                                    onClick={() => setOpenItem(openItem === index ? null : index)}
                                >
                                    <span className="font-medium text-gray-900 dark:text-white">
                                        {faq.question}
                                    </span>
                                    <svg
                                        className={`w-5 h-5 text-gray-500 dark:text-gray-400 transition-transform ${
                                            openItem === index ? 'transform rotate-180' : ''
                                        }`}
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M19 9l-7 7-7-7"
                                        />
                                    </svg>
                                </button>
                                {openItem === index && (
                                    <div className="px-6 pb-4">
                                        <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                                            <p className="text-gray-600 dark:text-gray-300">
                                                {faq.answer}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                    {/* Contact CTA */}
                    <div className="text-center mt-12">
                        <p className="text-gray-600 dark:text-gray-300 mb-4">
                            Still have questions?
                        </p>
                        <Link
                            href="/contact"
                            className="inline-flex items-center bg-primary text-white px-6 py-3 rounded-lg hover:bg-primary/90 transition-colors"
                        >
                            Contact Our Team
                            <svg
                                className="w-5 h-5 ml-2"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                                />
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}