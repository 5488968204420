'use client';

import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { services } from '@/data/services';

const Services: React.FC = () => {
    return (
        <section className="py-16 bg-white dark:bg-gray-900">
            <div className="container mx-auto px-4">
                {/* Section Header */}
                <div className="text-center mb-12">
                    <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                        Our Services
                    </h2>
                    <p className="text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
                        Comprehensive solutions for your international trade needs
                    </p>
                </div>

                {/* Services Grid */}
                <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
                    {services.map((service) => (
                        <div 
                            key={service.id}
                            className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-[rgba(17,17,26,0.1)_0px_0px_16px] dark:shadow-[rgba(0,0,0,0.1)_0px_0px_16px] group flex flex-col h-full"
                        >
                            {/* Image Container */}
                            <div className="relative h-48 overflow-hidden bg-gray-200 dark:bg-gray-700 flex-shrink-0">
                                <Image
                                    src={service.image}
                                    alt={service.title}
                                    fill
                                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 33vw"
                                    className="object-cover group-hover:scale-105 transition-transform duration-300"
                                    quality={85}
                                />
                            </div>

                            {/* Content */}
                            <div className="p-6 flex flex-col flex-grow">
                                {/* Main Content */}
                                <div className="flex-grow">
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                                        {service.title}
                                    </h3>
                                    
                                    <p className="text-gray-600 dark:text-gray-300 mb-4">
                                        {service.description}
                                    </p>

                                    {/* Features List */}
                                    <ul className="space-y-2">
                                        {service.features.slice(0, 4).map((feature, index) => (
                                            <li 
                                                key={index}
                                                className="flex items-center text-gray-600 dark:text-gray-300"
                                            >
                                                <svg 
                                                    className="w-4 h-4 text-primary mr-2 flex-shrink-0" 
                                                    fill="none" 
                                                    stroke="currentColor" 
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path 
                                                        strokeLinecap="round" 
                                                        strokeLinejoin="round" 
                                                        strokeWidth={2} 
                                                        d="M5 13l4 4L19 7" 
                                                    />
                                                </svg>
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* Learn More Link */}
                                <div className="mt-6 pt-6 border-t border-gray-100 dark:border-gray-700">
                                    <Link
                                        href={service.blogLink || `/services/${service.id}`}
                                        className="inline-flex items-center text-primary hover:text-primary/90 dark:text-blue-400 dark:hover:text-blue-300 font-semibold transition-colors"
                                    >
                                        {`Details: ${service.title}`}
                                        <svg 
                                            className="w-4 h-4 ml-2" 
                                            fill="none" 
                                            stroke="currentColor" 
                                            viewBox="0 0 24 24"
                                        >
                                            <path 
                                                strokeLinecap="round" 
                                                strokeLinejoin="round" 
                                                strokeWidth={2} 
                                                d="M9 5l7 7-7 7" 
                                            />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* CTA Button */}
                <div className="text-center mt-12">
                    <Link
                        href="/contact"
                        className="inline-flex items-center bg-primary text-white px-6 py-3 rounded-lg hover:bg-primary/90 transition-colors"
                    >
                        Get Started with Our Services
                        <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Services;
