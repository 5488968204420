'use client';

import { useState, useCallback, useEffect } from 'react';
import Image from 'next/image';

interface Testimonial {
    id: number;
    text: string;
    clientName: string;
    industry: string;
    region: string;
    productImage: string;
    productCategory: string;
    blurDataURL: string;
}

const testimonials: Testimonial[] = [
    {
        id: 1,
        text: "Working with Continuation Trade has transformed our sourcing process. They helped us find the perfect manufacturer and handled all quality control aspects professionally.",
        clientName: "Client A",
        industry: "Electronics Manufacturing",
        region: "North America",
        productImage: "/images/testimonials/electronic-components.jpg",
        productCategory: "Electronic Components",
        blurDataURL: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQdHx0fHRsdHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR3/2wBDAR0XFyAeIRMeIR0dITcdHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR3/wAARCAAIAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAX/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCdABmX/9k="
    },
    {
        id: 2,
        text: "Their attention to detail in quality control and packaging saved us from potential issues. The shipping process was smooth and well-managed.",
        clientName: "Client B",
        industry: "Home Appliances",
        region: "Europe",
        productImage: "/images/testimonials/kitchen-appliances.jpg",
        productCategory: "Kitchen Appliances",
        blurDataURL: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQdHx0fHRsdHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR3/2wBDAR0XFyAeIRMeIR0dITcdHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR3/wAARCAAIAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAn/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAX/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCdABmX/9k="
    },
    {
        id: 3,
        text: "Excellent communication throughout the entire process. They helped us navigate customs clearance efficiently and provided real-time updates.",
        clientName: "Client C",
        industry: "Retail",
        region: "Australia",
        productImage: "/images/testimonials/consumer-goods.jpg",
        productCategory: "Consumer Goods",
        blurDataURL: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQdHx0fHRsdHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR3/2wBDAR0XFyAeIRMeIR0dITcdHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR3/wAARCAAIAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAX/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCdABmX/9k="
    }
];

const Testimonials: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState<'left' | 'right'>('right');

    // Memoize handlers to prevent recreating functions on each render
    const nextTestimonial = useCallback(() => {
        setDirection('right');
        setCurrentIndex((prevIndex) => 
            prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
        );
    }, []);

    const prevTestimonial = useCallback(() => {
        setDirection('left');
        setCurrentIndex((prevIndex) => 
            prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
        );
    }, []);

    const goToTestimonial = useCallback((index: number) => {
        setDirection(index > currentIndex ? 'right' : 'left');
        setCurrentIndex(index);
    }, [currentIndex]);

    // Add cleanup for state when component unmounts
    useEffect(() => {
        return () => {
            setCurrentIndex(0);
            setDirection('right');
        };
    }, []);

    return (
        <section className="py-16 bg-gray-50 dark:bg-gray-900">
            <div className="container mx-auto px-4">
                {/* Section Header */}
                <div className="text-center mb-12">
                    <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                        Success Stories
                    </h2>
                    <p className="text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
                        Real results from our clients across the globe. See how we've helped businesses 
                        source and import quality products from China.
                    </p>
                </div>

                {/* Testimonial Slider */}
                <div className="max-w-6xl mx-auto">
                    <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-[rgba(17,17,26,0.1)_0px_0px_16px] dark:shadow-[rgba(0,0,0,0.1)_0px_0px_16px] overflow-hidden">
                        <div className="grid md:grid-cols-2 h-[600px]">
                            {/* Product Image */}
                            <div className="relative h-64 md:h-full bg-gray-100 dark:bg-gray-800 overflow-hidden">
                                {testimonials.map((testimonial, index) => (
                                    <div
                                        key={testimonial.id}
                                        className={`absolute inset-0 transition-transform duration-500 ease-in-out ${
                                            index === currentIndex ? 'translate-x-0' : 
                                            index < currentIndex ? '-translate-x-full' : 'translate-x-full'
                                        }`}
                                    >
                                        <Image
                                            src={testimonial.productImage}
                                            alt={testimonial.productCategory}
                                            fill
                                            placeholder="blur"
                                            blurDataURL={testimonial.blurDataURL}
                                            className="object-cover"
                                            sizes="(max-width: 768px) 100vw, 50vw"
                                            quality={85}
                                        />
                                    </div>
                                ))}
                                <div className="absolute bottom-4 left-4 bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm px-4 py-2 rounded-lg z-10">
                                    <span className="text-sm font-medium text-gray-900 dark:text-white">
                                        {testimonials[currentIndex].productCategory}
                                    </span>
                                </div>
                            </div>

                            {/* Testimonial Content */}
                            <div className="p-8 flex flex-col h-full">
                                {/* Fixed Height Content Container - Responsive heights */}
                                <div className="h-[250px] sm:h-[300px] md:h-[400px] overflow-y-auto"> {/* Different heights for different screens */}
                                    <div className={`transition-opacity duration-300 ${
                                        direction === 'right' ? 'animate-fadeInRight' : 'animate-fadeInLeft'
                                    }`}>
                                        {/* Quote Icon */}
                                        <svg 
                                            className="w-8 h-8 sm:w-10 sm:h-10 text-primary/20 dark:text-primary/30 mb-4"
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" />
                                        </svg>

                                        {/* Testimonial Text */}
                                        <p className="text-gray-600 dark:text-gray-300 text-base sm:text-lg mb-4 sm:mb-6">
                                            {testimonials[currentIndex].text}
                                        </p>

                                        {/* Client Info */}
                                        <div>
                                            <p className="font-semibold text-gray-900 dark:text-white">
                                                {testimonials[currentIndex].clientName}
                                            </p>
                                            <p className="text-gray-500 dark:text-gray-400 text-sm">
                                                {testimonials[currentIndex].industry}
                                            </p>
                                            <p className="text-gray-500 dark:text-gray-400 text-sm">
                                                {testimonials[currentIndex].region}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Navigation Section - Added responsive padding */}
                                <div className="pt-3 sm:pt-4 mt-auto border-t border-gray-100 dark:border-gray-700">
                                    <div className="flex justify-between items-center">
                                        {/* Dots */}
                                        <div className="flex space-x-1">
                                            {testimonials.map((_, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() => goToTestimonial(index)}
                                                    className={`p-2 group`}
                                                    aria-label={`Go to testimonial ${index + 1}`}
                                                >
                                                    <div className={`w-2.5 h-2.5 sm:w-3 sm:h-3 rounded-full transition-colors ${
                                                        index === currentIndex 
                                                        ? 'bg-primary' 
                                                        : 'bg-gray-300 dark:bg-gray-600 group-hover:bg-gray-400 dark:group-hover:bg-gray-500'
                                                    }`} />
                                                </button>
                                            ))}
                                        </div>

                                        {/* Navigation Arrows - Added responsive sizing */}
                                        <div className="flex space-x-1 sm:space-x-2">
                                            <button
                                                onClick={prevTestimonial}
                                                className="p-1.5 sm:p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                                                aria-label="Previous testimonial"
                                            >
                                                <svg 
                                                    className="w-4 h-4 sm:w-5 sm:h-5 text-gray-600 dark:text-gray-300" 
                                                    fill="none" 
                                                    stroke="currentColor" 
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                                </svg>
                                            </button>
                                            <button
                                                onClick={nextTestimonial}
                                                className="p-1.5 sm:p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                                                aria-label="Next testimonial"
                                            >
                                                <svg 
                                                    className="w-4 h-4 sm:w-5 sm:h-5 text-gray-600 dark:text-gray-300" 
                                                    fill="none" 
                                                    stroke="currentColor" 
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;