export interface BlogPost {
    id: number;
    title: string;
    excerpt: string;
    category: string;
    date: string;
    image: string;
    readTime?: string;
    slug?: string;
    blurDataURL?: string;
}

// Common blur data URL for placeholder images
const defaultBlurDataURL = "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQdHx0fHRsdHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR3/wAARCAAIAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAX/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCdABmX/9k=";

export const blogPosts: BlogPost[] = [
    {
        id: 1,
        title: "Complete Guide to Finding Reliable Chinese Manufacturers",
        excerpt: "Learn how to identify, verify, and work with trustworthy manufacturers in China. Key tips for successful supplier relationships.",
        image: "/images/blog/trade-regulations.jpg",
        category: "Sourcing Guide",
        date: "March 15, 2024",
        readTime: "5 min read",
        slug: "manufacturer-guide",
        blurDataURL: defaultBlurDataURL
    },
    {
        id: 2,
        title: "Quality Control Tips When Manufacturing in China",
        excerpt: "Essential quality control steps to ensure your products meet specifications. From factory audits to final inspections.",
        image: "/images/blog/supply-chain-logistics.jpg",
        category: "Quality Control",
        date: "March 10, 2024",
        readTime: "4 min read",
        slug: "quality-control-tips",
        blurDataURL: defaultBlurDataURL
    },
    {
        id: 3,
        title: "Shipping from China: A Complete Guide for Importers",
        excerpt: "Everything you need to know about shipping methods, costs, customs clearance, and delivery times from China.",
        image: "/images/blog/emerging-markets.jpg",
        category: "Logistics",
        date: "March 5, 2024",
        readTime: "6 min read",
        slug: "shipping-guide",
        blurDataURL: defaultBlurDataURL
    },
    {
        id: 4,
        title: "Complete Guide to Quality Control in China",
        excerpt: "Learn essential strategies for maintaining product quality when manufacturing in China.",
        category: "Quality Control",
        date: "March 15, 2024",
        image: "/images/blogs/quality-control.jpg",
        readTime: "5 min read",
        slug: "quality-control-guide",
        blurDataURL: defaultBlurDataURL
    },
    {
        id: 5,
        title: "Understanding Shipping Methods & Costs",
        excerpt: "Compare different shipping options and learn how to optimize your logistics costs.",
        category: "Logistics",
        date: "March 12, 2024",
        image: "/images/blogs/shipping-methods.jpg",
        readTime: "4 min read",
        slug: "shipping-methods-guide",
        blurDataURL: defaultBlurDataURL
    },
    {
        id: 6,
        title: "Sourcing from Chinese Trade Shows",
        excerpt: "A comprehensive guide to finding reliable suppliers at major Chinese trade shows.",
        category: "Sourcing",
        date: "March 10, 2024",
        image: "/images/blogs/trade-shows.jpg",
        readTime: "5 min read",
        slug: "trade-shows-guide",
        blurDataURL: defaultBlurDataURL
    }
];

// For homepage, we might want to show only the latest 3 posts
export const getLatestPosts = (count: number = 3): BlogPost[] => {
    return blogPosts.slice(0, count);
};

// Helper function to get a post by slug
export const getPostBySlug = (slug: string): BlogPost | undefined => {
    return blogPosts.find(post => post.slug === slug);
};

// Helper function to get related posts by category
export const getRelatedPosts = (category: string, currentPostId: number, limit: number = 3): BlogPost[] => {
    return blogPosts
        .filter(post => post.category === category && post.id !== currentPostId)
        .slice(0, limit);
}; 