'use client';

import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { getLatestPosts } from '@/data/blogPosts';

const Blogs: React.FC = () => {
    const latestPosts = getLatestPosts(3); // Get only 3 latest posts for homepage

    return (
        <section className="py-16 bg-gray-50 dark:bg-gray-900">
            <div className="container mx-auto px-4">
                {/* Section Header */}
                <div className="text-center mb-12">
                    <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                        Latest Insights
                    </h2>
                    <p className="text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
                        Expert advice and practical guides on importing from China
                    </p>
                </div>

                {/* Blog Posts Grid */}
                <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
                    {latestPosts.map((post) => (
                        <Link
                            key={post.id}
                            href={`/blog/${post.slug}`}
                            className="group bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-[rgba(17,17,26,0.1)_0px_0px_16px] dark:shadow-[rgba(0,0,0,0.1)_0px_0px_16px] hover:shadow-[rgba(17,17,26,0.1)_0px_0px_24px] dark:hover:shadow-[rgba(0,0,0,0.2)_0px_0px_24px] transition-shadow flex flex-col h-full"
                        >
                            {/* Image Container */}
                            <div className="relative h-48 overflow-hidden">
                                <Image
                                    src={post.image}
                                    alt={post.title}
                                    fill
                                    placeholder="blur"
                                    blurDataURL={post.blurDataURL}
                                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 33vw"
                                    className="object-cover group-hover:scale-105 transition-transform duration-300"
                                    quality={85}
                                />
                                {/* Category Badge */}
                                <div className="absolute top-4 left-4">
                                    <span className="bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm px-3 py-1 rounded-full text-sm font-medium text-primary">
                                        {post.category}
                                    </span>
                                </div>
                            </div>
                            
                            {/* Content */}
                            <div className="p-6 flex flex-col flex-grow">
                                <div className="flex-grow">
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2 group-hover:text-primary transition-colors">
                                        {post.title}
                                    </h3>
                                    
                                    <p className="text-gray-600 dark:text-gray-300 mb-4 line-clamp-2">
                                        {post.excerpt}
                                    </p>
                                </div>
                                
                                <div className="flex items-center justify-between mt-4 pt-4 border-t border-gray-100 dark:border-gray-700">
                                    <span className="text-sm text-gray-500 dark:text-gray-400">
                                        {post.date}
                                    </span>
                                    <span className="text-primary dark:text-blue-400 group-hover:translate-x-1 transition-transform inline-flex items-center">
                                        Read More
                                        <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>

                {/* View All Button */}
                <div className="text-center mt-12">
                    <Link
                        href="/blog"
                        className="inline-flex items-center bg-white dark:bg-gray-800 text-primary dark:text-blue-400 hover:bg-primary hover:text-white dark:hover:bg-blue-500 dark:hover:text-white px-6 py-3 rounded-lg border-2 border-primary dark:border-blue-400 dark:hover:border-blue-500 transition-colors"
                    >
                        View All Articles
                        <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Blogs;
