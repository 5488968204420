export interface Service {
    id: number;
    title: string;
    description: string;
    shortDescription?: string;
    features: string[];
    image: string;
    blogLink?: string;
}

export const services: Service[] = [
    {
        id: 1,
        title: "Product Sourcing",
        description: "We find the best Chinese manufacturers and negotiate prices for your products. Our team verifies suppliers to ensure you work with reliable partners.",
        shortDescription: "Find and verify reliable manufacturers in China",
        features: [
            "Factory verification and selection",
            "Price negotiation with suppliers",
            "Sample ordering and testing",
            "Product customization support",
            "Quality specification development"
        ],
        image: "/images/services/product-sourcing.jpg",
        blogLink: "/blog/sourcing-guide"
    },
    {
        id: 2,
        title: "Quality Control",
        description: "We protect your interests by inspecting products at every stage. Our team in China visits factories to ensure your products meet all specifications.",
        shortDescription: "Professional inspection services throughout production",
        features: [
            "Factory audit inspections",
            "Pre-production checks",
            "During production monitoring",
            "Final quality inspections",
            "Product testing and certification"
        ],
        image: "/images/services/quality-control.jpg",
        blogLink: "/blog/quality-control"
    },
    {
        id: 3,
        title: "Shipping & Logistics",
        description: "We handle the complete shipping process from Chinese factories to your door. Our team manages customs, documentation, and delivery tracking.",
        shortDescription: "Complete shipping management from China to your door",
        features: [
            "International freight booking",
            "Export documentation handling",
            "Customs clearance assistance",
            "Door-to-door delivery",
            "Shipment tracking and updates"
        ],
        image: "/images/services/shipping-logistics.jpg",
        blogLink: "/blog/logistics-guide"
    }
];

// Helper function to get a service by ID
export const getServiceById = (id: number): Service | undefined => {
    return services.find(service => service.id === id);
}; 