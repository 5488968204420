'use client';

import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

interface Statistic {
    value: string;
    label: string;
}

const statistics: Statistic[] = [
    { value: "1000+", label: "Products Sourced" },
    { value: "50+", label: "Countries Served" },
    { value: "98%", label: "Client Satisfaction" },
    { value: "24/7", label: "Support Available" },
];

const Hero: React.FC = () => {
    return (
        <section className="relative bg-gray-50 dark:bg-gray-900 overflow-hidden">
            {/* Background Pattern */}
            <div className="absolute inset-0 bg-grid-pattern opacity-5" />

            <div className="relative container mx-auto px-4 py-16 lg:py-24">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                    {/* Left Column - Content */}
                    <div className="max-w-xl">
                        <h1 className="text-4xl md:text-5xl font-bold mb-6 [text-wrap:balance]">
                            <span className="inline-block min-w-[7ch] text-gray-900 dark:text-white transition-colors">Welcome to</span>{' '}
                            <span className="text-primary">
                                Continuation Trade
                            </span>
                        </h1>
                        
                        <p className="text-lg text-gray-600 dark:text-gray-300 mb-8 [text-wrap:balance] max-w-[60ch] font-medium">
                            <span className="inline-block">
                                Your trusted sourcing agent in China. We find and purchase products from reliable manufacturers, handle quality inspections, and manage shipping to your destination - making importing from China simple and secure.
                            </span>
                        </p>

                        {/* CTA Buttons */}
                        <div className="flex flex-wrap gap-4 mb-12">
                            <Link
                                href="/contact"
                                className="bg-primary text-white px-8 py-3 rounded-lg hover:bg-primary/90 transition-colors inline-flex items-center"
                            >
                                Get Started
                                <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                                </svg>
                            </Link>
                            
                            <Link
                                href="/services"
                                className="border-2 border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 px-8 py-3 rounded-lg hover:border-primary hover:text-primary dark:hover:border-primary dark:hover:text-primary transition-colors inline-flex items-center"
                            >
                                Our Services
                                <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                            </Link>
                        </div>

                        {/* Statistics */}
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                            {statistics.map((stat, index) => (
                                <div key={index} className="text-center">
                                    <div className="text-2xl font-bold text-primary mb-1">
                                        {stat.value}
                                    </div>
                                    <div className="text-sm text-gray-600">
                                        {stat.label}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Right Column - Image */}
                    <div className="relative">
                        <div className="relative rounded-2xl overflow-hidden bg-gray-200 dark:bg-gray-800">
                            <Image
                                src="/images/index/hero-background.jpg"
                                alt="Global Trade and Logistics"
                                width={800}
                                height={500}
                                className="rounded-2xl shadow-2xl w-full"
                                priority
                                quality={85}
                                placeholder="blur"
                                blurDataURL="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQdHx4eHRseHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh7/2wBDAR0XFx4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh7/wAARCAAIAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAX/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCdABmX/9k="
                                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 800px"
                            />
                            
                            {/* Floating Elements */}
                            <div className="absolute top-4 left-4 md:top-8 md:left-8 bg-white dark:bg-gray-800 p-3 md:p-4 rounded-lg shadow-lg">
                                <div className="flex items-center space-x-2 md:space-x-3">
                                    <svg className="w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <div>
                                        <div className="font-semibold text-gray-900 dark:text-white text-sm md:text-sm lg:text-base">Quality Assured</div>
                                        <div className="text-xs md:text-xs lg:text-sm text-gray-600 dark:text-gray-300">100% Verification</div>
                                    </div>
                                </div>
                            </div>

                            <div className="absolute bottom-4 right-4 md:bottom-8 md:right-8 bg-white dark:bg-gray-800 p-3 md:p-4 rounded-lg shadow-lg">
                                <div className="flex items-center space-x-2 md:space-x-3">
                                    <svg className="w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                                    </svg>
                                    <div>
                                        <div className="font-semibold text-gray-900 dark:text-white text-sm md:text-sm lg:text-base">Fast Shipping</div>
                                        <div className="text-xs md:text-xs lg:text-sm text-gray-600 dark:text-gray-300">Global Delivery</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
