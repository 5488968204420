'use client';

import React from 'react';
import Link from 'next/link';

interface ProcessStep {
    id: number;
    title: string;
    description: string;
    icon: React.ReactNode;
}

const ProcessSteps: ProcessStep[] = [
    {
        id: 1,
        title: "Tell Us Your Needs",
        description: "Share your product requirements with us. We'll understand your specifications, target price, and quantity needs to start sourcing from Chinese factories.",
        icon: (
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
        ),
    },
    {
        id: 2,
        title: "Get Factory Quotes",
        description: "We'll find the best factories, get detailed quotes, and send you product samples. Compare options and prices before making your decision.",
        icon: (
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
            </svg>
        ),
    },
    {
        id: 3,
        title: "Place Your Order",
        description: "Once you approve the sample and price, we'll handle the factory order, negotiate terms, and manage production timelines for you.",
        icon: (
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        ),
    },
    {
        id: 4,
        title: "Quality Checks",
        description: "Our team in China inspects your products during and after production. We ensure everything meets your specifications before shipping.",
        icon: (
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
            </svg>
        ),
    },
    {
        id: 5,
        title: "Safe Shipping",
        description: "We arrange the best shipping method, handle all export documents, and manage customs clearance to deliver products to your door.",
        icon: (
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
            </svg>
        ),
    },
    {
        id: 6,
        title: "Ongoing Support",
        description: "Get your products safely and on time. We're here to help with future orders and ensure your long-term importing success from China.",
        icon: (
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
            </svg>
        ),
    },
];

const Process: React.FC = () => {
    return (
        <section className="py-16 bg-gray-50 dark:bg-gray-900">
            <div className="container mx-auto px-4">
                {/* Section Header */}
                <div className="text-center mb-12">
                    <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                        Simple Sourcing Process
                    </h2>
                    <p className="text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
                        Your dedicated sourcing agent handling everything from factory selection to delivery. 
                        Just tell us what you need, and we'll manage the entire import process.
                    </p>
                </div>

                {/* Process Steps */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
                    {ProcessSteps.map((step) => (
                        <div
                            key={step.id}
                            className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-[rgba(17,17,26,0.1)_0px_0px_16px] dark:shadow-[rgba(0,0,0,0.1)_0px_0px_16px] hover:shadow-[rgba(17,17,26,0.1)_0px_0px_24px] dark:hover:shadow-[rgba(0,0,0,0.2)_0px_0px_24px] transition-shadow"
                        >
                            <div className="flex items-center mb-4">
                                <div className="bg-primary/10 dark:bg-primary/20 rounded-lg p-3 mr-4">
                                    <div className="text-primary">
                                        {step.icon}
                                    </div>
                                </div>
                                <span className="text-primary dark:text-blue-400 font-semibold">
                                    Step {step.id}
                                </span>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                                {step.title}
                            </h3>
                            <p className="text-gray-600 dark:text-gray-300">
                                {step.description}
                            </p>
                        </div>
                    ))}
                </div>

                {/* Call to Action */}
                <div className="text-center mt-12">
                    <Link 
                        href="/contact" 
                        className="inline-block bg-primary text-white px-8 py-3 rounded-lg hover:bg-primary/90 transition-colors"
                    >
                        Start Your Import Journey
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Process;
