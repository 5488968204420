// components/Header.tsx
"use client"
import { useEffect, useState } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

const Header: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const pathname = usePathname();

    // Close menu when route changes
    useEffect(() => {
        setIsMenuOpen(false);
    }, [pathname]);

    const navLinks = [
        { href: '/', label: 'Home' },
        { href: '/about', label: 'About' },
        { href: '/services', label: 'Services' },
        { href: '/blog', label: 'Blog' }
    ];

    return (
        <header className="fixed top-0 left-0 right-0 z-50 bg-white/95 dark:bg-gray-900/95 backdrop-blur-sm transition-colors shadow-sm">
            <div className="container mx-auto px-4 py-4">
                <nav className="flex justify-between items-center">
                    <Link href="/" className="text-2xl font-bold text-gray-800 dark:text-white whitespace-nowrap">
                        Continuation Trade
                    </Link>
                    <button 
                        className="lg:hidden text-white bg-primary rounded-lg px-4 py-2 transition duration-300 hover:bg-primary/90" 
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                        {isMenuOpen ? 'Close' : 'Menu'}
                    </button>
                    <ul className={`flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-4 ${isMenuOpen ? 'flex' : 'hidden'} lg:flex absolute lg:static bg-white dark:bg-gray-900 w-full lg:w-auto top-full left-0 z-40 p-4 lg:p-0 border-t border-gray-100 dark:border-gray-700 lg:border-t-0`}>
                        {navLinks.map((link) => (
                            <li key={link.href}>
                                <Link 
                                    href={link.href} 
                                    className={`text-gray-800 dark:text-gray-200 hover:text-primary dark:hover:text-primary no-underline block px-4 py-2 text-lg ${pathname === link.href ? 'font-bold' : ''}`}
                                >
                                    {link.label}
                                </Link>
                            </li>
                        ))}
                        <li className="flex items-center justify-center lg:justify-start">
                            <Link 
                                href="/contact" 
                                className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary/90 transition-colors text-lg inline-block max-w-[200px] text-center"
                            >
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
